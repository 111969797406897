import React from "react"
import flexeetLogoWhite from "../images/FlexeetLogoCyan.svg"
import IconLink from "./IconLink"
import twitterW from "../images/Icons/TwitterW.svg"
import igW from "../images/Icons/InstagramW.svg"
import mailW from "../images/Icons/MailW.svg"
import FooterLinks from "./footerLinks"
export const Footer = () => {
  const ProductLinks = [
    {
      url: "https://app.flexeet.club",
      title: "All Templates",
      target: "_self",
    },

    {
      url: "https://flexeetclub.olvy.co/",
      title: "Release Notes",
      target: "_blank",
    },
  ]
  const CompanyLinks = [
    {
      url: "/team",
      title: "Team",
      target: "_self",
    },

    {
      url: "https://pages.razorpay.com/flexeetclub",
      title: "Support Us",
      target: "_blank",
    },
  ]
  const LegalLinks = [
    {
      url:
        "https://www.notion.so/Terms-Conditions-39342d928a6b4ea0b59fc26de2a275b8",
      title: "Terms & Conditions",
      target: "_blank",
    },
    {
      url:
        "https://www.notion.so/Privacy-Policy-a49142a92ebd4a32b4afe17f1cf0a0d9",
      title: "Privacy Policy",
      target: "_blank",
    },
  ]

  return (
    <div className="bgGradient">
      <div className="flex flex-col content-start lg:justify-between lg:flex-row px-4 lg:px-32 pt-12 mb-20">
        <div className="grid place-content-center justify-items-center lg:justify-items-start lg:place-content-start">
          <a href="/">
            <img src={flexeetLogoWhite} alt="logo" className="m-0" />
          </a>
          <div className="deskPB textCyan py-4">
            Flex Your Tweet With Flexeet
          </div>
          <div className="flex flex-row justify-center lg:justify-start gap-y-4 gap-x-4 flex-wrap">
            <IconLink
              title="Instagram"
              url="https://www.instagram.com/flexeetclub/"
              iconSVG={igW}
              type="footer"
            />
            <IconLink
              title="Twitter"
              url="https://www.twitter.com/flexeetclub/"
              iconSVG={twitterW}
              type="footer"
            />
            <IconLink
              title="hello@flexeet.club"
              url="mailto:hello@flexeet.club"
              iconSVG={mailW}
              type="footer"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row m-0 lg:gap-x-20 gap-y-10">
          <FooterLinks heading="Product" links={ProductLinks} />
          <FooterLinks heading="Company" links={CompanyLinks} />
          <FooterLinks heading="Legal" links={LegalLinks} />
        </div>
      </div>

      <div className="mobCTA-SMALL textCyan text-center self-center text-sm pb-8">
        Made with ❤️ in India <br />© 2021 All rights reserved
      </div>
    </div>
  )
}
