import React from "react"

const footerLinks = ({ heading, links }) => {
  return (
    <div className="flex flex-col">
      <div className="mobCTA-BIG md:deskCTA-BIG textCyan opacity-50 mb-4 lg:mb-8">
        {heading}
      </div>
      <div className="flex flex-row lg:flex-col gap-x-10 gap-y-4 flex-wrap">
        {links.map(item => (
          <a href={item["url"]} target={item.target}>
            <div className="mobCTA-SMALL md:deskCTA-SMALL textCyan">
              {item["title"]}
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default footerLinks
