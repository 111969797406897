import React from "react"
import Loader from "../images/Icons/Loader.svg"
import SLoader from "../images/Icons/SLoader.svg"
var classNames = require("classnames")

const Button = ({
  title,
  buttonStyle,
  buttonSize,
  isDisabled,
  isLoading,
  fitType,
  onBtnClick,
}) => {
  const STYLES = [`btnPrimary`, `btnSecondary`, `btnCyan`]
  const SIZES = [`btnSmall`, `btnBig`]
  const FITS = [`btnFit`, `btnFull`]

  const checkButtonSize = SIZES.includes(buttonSize)
    ? buttonSize === "btnSmall"
      ? "btnSmallMob md:btnSmall lg:btnSmall"
      : buttonSize
    : SIZES[0]

  const checkButtonFit = FITS.includes(fitType) ? fitType : SIZES[0]

  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0]

  const textColors = {
    btnPrimary: "textCyan",
    btnSecondary: "textGradientspan",
    btnCyan: "textGradient",
  }

  const setTextSize =
    checkButtonSize === "btnBig"
      ? "mobCTA-BIG lg:deskCTA-BIG"
      : `mobCTA-SMALL lg:deskCTA-SMALL`

  return (
    <button
      className={classNames(checkButtonStyle, checkButtonSize, checkButtonFit)}
      disabled={isDisabled}
      onClick={onBtnClick}
    >
      {!isLoading ? (
        <span className={classNames(textColors[checkButtonStyle], setTextSize)}>
          {title}
        </span>
      ) : (
        <img
          src={buttonStyle === "btnPrimary" ? Loader : SLoader}
          alt="loader"
          className="m-0 animate-spin"
        />
      )}
    </button>
  )
}

export default Button
